








































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { formatDateTimeString } from '@/utils/dateUtils'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  rowActions = []
  private appendSitePrefix = appendSitePrefix
  private useRewrittenTyreHotelView = false

  mounted() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Time',
        value: 'readingTime',
        filter: { disable: true },
      },
      {
        text: 'Device Name',
        value: 'treadDepthReader.name',
      },
      {
        text: 'Car',
        value: 'car',
      },
      {
        text: 'Tyre Hotel',
        value: 'tyreHotelId',
      },
      {
        text: 'Season',
        value: 'season',
      },
      {
        text: 'c:number-of:Tyres',
        value: 'tyreCount',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'c:tread-depth:Minimum',
        value: 'minimumMeasurement',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'c:tread-depth:Readings',
        value: 'measurementsText',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'edit',
        route: (item) => {
          return { name: 'Car/TreadDepthReading/Edit', params: { id: item.id } }
        },
      },
      {
        id: 'show',
        route: (item) => {
          return { name: 'Car/TreadDepthReading/Show', params: { id: item.id } }
        },
      },
      {
        id: 'publicUrl',
        label: 'c:tread-depth-reading-list:Public URL',
        absolute: true,
        route: (item) => {
          return item.publicUrl
        },
      },
    ]
  }

  formatTime(value) {
    return formatDateTimeString(value)
  }

  getMinimumMeasurement(tyres) {
    let lowest = null
    for (let i = 0; i < tyres.length; i++) {
      if (lowest === null || tyres[i].primaryMeasurement < lowest) {
        lowest = tyres[i].primaryMeasurement
      }
    }
    return lowest === null ? '' : lowest
  }

  onData(data): void {
    this.useRewrittenTyreHotelView = data.responseData.useRewrittenTyreHotelView
  }
}
