var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-view',{attrs:{"headers":_vm.headers,"url":"/v4/site/tread-depth-readings","title":"Tread depth readings","sort-by":"readingTime","sort-desc":"","row-actions":_vm.rowActions},on:{"data":_vm.onData},scopedSlots:_vm._u([{key:"item.measurementsText",fn:function(ref){
var item = ref.item;
return _vm._l((item.tyres),function(tyre){return _c('span',{staticClass:"measurement"},[_vm._v(_vm._s(tyre.primaryMeasurement))])})}},{key:"item.readingTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.readingTime))+" ")]}},{key:"item.tyreCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tyres.length)+" ")]}},{key:"item.minimumMeasurement",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMinimumMeasurement(item.tyres))+" ")]}},{key:"item.car",fn:function(ref){
var item = ref.item;
return [(item.car)?_c('span',[_c('router-link',{attrs:{"to":_vm.appendSitePrefix('/' + item.car.customer.id + '/customer/show?car_id=' + item.car.id),"target":"_blank"}},[_vm._v(" "+_vm._s(item.car.licenseplate)+" ")])],1):(item.licensePlate)?_c('span',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.licensePlate))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":_vm.appendSitePrefix('/N0/car/new?reg_no=' + item.licensePlate),"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fa-info-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('c:tread-depth:This car does not exist, click here to create it')))])])],1):_c('span',[_vm._v(" - ")])]}},{key:"item.tyreHotelId",fn:function(ref){
var item = ref.item;
return [(_vm.useRewrittenTyreHotelView)?[(item.tyreHotelId)?_c('router-link',{attrs:{"to":{
          name: 'TyreHotel/View',
          params: {
            tyreHotelId: item.tyreHotelId,
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.tyreHotelId)+" ")]):_vm._e()]:[(item.tyreHotelId)?_c('router-link',{attrs:{"to":_vm.appendSitePrefix('/tyre_hotel/' + item.tyreHotelId + '/storage/item/null'),"target":"_blank"}},[_vm._v(" "+_vm._s(item.tyreHotelId)+" ")]):_vm._e()]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }